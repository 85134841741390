module type Sig = sig
	val version : string
	val pretty : unit -> string
end
module Make(Re:Re_ext.Sig) = struct
	let version="0.1.1"
	let commit = Some "b6e7bbfdd4"
	let parse v =
		let dot = Re.(regexp_string ".") in
		let v =
			try String.sub v 0 (String.rindex v '-')
			with Not_found -> v in
		let parts = Re.(split dot v) in
		let parts = List.map int_of_string parts in
		match parts with
			| maj :: min :: patch :: _ -> (maj, min, patch)
			| _ -> Error.raise_assert ("unparseable version: " ^ v)

	let minor (maj, min, _patch) = (maj, min)

	let pretty () = match commit with
		| Some c -> version ^ "-" ^ c
		| None -> version
end
